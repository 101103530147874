import "core-js/modules/es.regexp.exec.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_c('pageLoadProgress'), _c('div', {
    staticClass: "flex flex-col min-h-screen justify-between"
  }, [_c('div', {
    staticClass: "flex-auto"
  }, [_vm.isRouterAlive ? _c('router-view') : _vm._e()], 1), /(Index|Profit|Recruit|User)$/.test(_vm.$route.name) ? _c('TabBar') : _vm._e()], 1), _c('weixin-authorize-user-info-mask'), _c('integral-sign-mask')], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };