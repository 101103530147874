import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.regexp.exec.js";
import LoadData from '@/features/api/components/LoadData';
import useMeStore from '@/features/auth/useMeStore';
const meStore = useMeStore();
export const currentCoach = (val = undefined) => {
  const cacheKey = 'LinelistSwitchCoachSelect';
  if (val) {
    sessionStorage.setItem(cacheKey, JSON.stringify(val));
  } else if (typeof val !== 'undefined') {
    sessionStorage.removeItem(cacheKey);
  }
  return JSON.parse(sessionStorage.getItem(cacheKey));
};
export default {
  name: 'LinelistSwitchCoach',
  components: {
    LoadData
  },
  props: {
    type: {
      type: String,
      default: 'page'
    }
  },
  data() {
    return {
      search: {
        type: 'mobile_phone',
        //uid,mobile_phone
        key: ''
      },
      select: currentCoach()
    };
  },
  watch: {
    select(val) {
      currentCoach(val);
    },
    type() {
      this.search.key = '';
      this.select = null;
    }
  },
  computed: {
    searchTypeTexts() {
      return {
        mobile_phone: '手机号',
        uid: 'UID'
      };
    },
    LinelistSwitchCoachSelect() {
      if (!meStore.me.qr_level) return null;
      const item = currentCoach();
      if (item) return item;
      return '';
    }
  },
  mounted() {},
  methods: {
    onRadioSelect(item) {
      var _this$select;
      this.select = item.id !== ((_this$select = this.select) === null || _this$select === void 0 ? void 0 : _this$select.id) ? item : null;
      this.$router.back();
    },
    switchKey() {
      this.search.type = this.search.type === 'uid' ? 'mobile_phone' : 'uid';
    },
    resHandle(res) {
      let {
        list = []
      } = res;
      const findIdx = list.findIndex(i => {
        var _this$select2;
        return i.id === ((_this$select2 = this.select) === null || _this$select2 === void 0 ? void 0 : _this$select2.id);
      });
      if (this.select) {
        findIdx >= 0 && list.splice(findIdx, 1);
        list.splice(0, 0, this.select);
      }
      return list.length && list;
    }
  }
};