import "core-js/modules/es.object.values.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.json.stringify.js";
import axios from '@/features/api/axios';
import debounce from 'lodash/debounce';
export default {
  name: 'LoadData',
  props: {
    path: {
      type: String,
      required: true
    },
    search: {
      type: Object,
      default: null
    },
    resHandler: {
      type: Function,
      default: null
    },
    validation: {
      type: Object,
      default: null
    },
    emptyDesc: {
      type: String,
      default: '暂无数据'
    }
  },
  data() {
    return {
      data: null,
      loading: false,
      error: null
    };
  },
  watch: {
    search: {
      handler(val) {
        const searchValJoin = Object.values(val).join('');
        if (this._lastSearchValJoin && this._lastSearchValJoin === searchValJoin) {
          return;
        }
        this._lastSearchValJoin = searchValJoin;
        try {
          this.validationSearch();
        } catch (e) {
          return;
        }
        this.fetchDebounce();
      },
      deep: true
    }
  },
  created() {
    this.fetch();
  },
  methods: {
    validationSearch() {
      if (!this.validation || !this.search) return;
      for (const k of Object.keys(this.validation)) {
        const regexp = this.validation[k];
        if (!this.search[k] || !regexp.test(this.search[k])) {
          throw new Error(`${k} validation faile`);
        }
      }
    },
    fetchDebounce: debounce(function () {
      return this.fetch(...arguments);
    }, 300),
    fetch(params = {}) {
      if (this.loading) return;
      this.loading = true;
      this.error = null;
      const path = this.path.replace('post:', '');
      const isPost = this.path.includes('post:');
      Object.assign(params, this.search || {});
      (isPost ? axios.post(path, params) : axios.get(path, {
        params
      })).then(res => {
        if (this.resHandler) res = this.resHandler(res);
        this.data = res;
      }).catch(e => {
        if (!e) {
          this.error = '网络错误';
        } else if (typeof e === 'object') {
          if (e.message) {
            this.error = e.message;
          } else {
            this.error = JSON.stringify(e);
          }
        } else {
          this.error = e;
        }
        // throw e;
      }).finally(() => {
        this.loading = false;
      });
    }
  }
};