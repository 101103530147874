var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "load-data"
  }, [_vm.data ? _vm._t("default", null, {
    "data": _vm.data
  }) : _vm.loading ? [_c('van-skeleton', {
    attrs: {
      "title": "",
      "avatar": "",
      "row": "3",
      "loading": _vm.loading
    }
  }), _c('van-loading', {
    staticClass: "mx-auto",
    attrs: {
      "vertical": ""
    }
  }, [_vm._v("加载中...")])] : [!_vm.data && !_vm.error ? _c('van-empty', {
    attrs: {
      "description": _vm.emptyDesc
    }
  }) : _vm.error ? _c('van-empty', {
    attrs: {
      "image": "error",
      "description": _vm.error
    }
  }, [_c('van-button', {
    attrs: {
      "round": ""
    },
    on: {
      "click": function ($event) {
        return _vm.fetch();
      }
    }
  }, [_vm._v("点击重试")])], 1) : _vm._e()]], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };