import fetch from '@/features/api/axios';
import * as api from './url';
import useMeStore from '@/features/auth/useMeStore';

const meStore = useMeStore();

export function addLesson(cityId, fieldId) {
  return fetch({
    url: api.addLesson,
    method: 'post',
    data: {
      cid: cityId,
      fid: fieldId,
    },
  });
}

export function batchSelect(lessonId, cate, field_id) {
  return fetch({
    url: api.batchSelect,
    method: 'post',
    data: {
      id: lessonId,
      cate: cate,
      field_id: field_id,
    },
  });
}

export function remove(id, mark, field_id) {
  return fetch({
    url: api.remove,
    method: 'post',
    data: {
      id: id,
      cate: mark,
      field_id: field_id,
    },
  });
}

export function myLesson(page = '1', cid = '') {
  return fetch({
    url: api.myLesson,
    method: 'get',
    params: {
      page: page,
      cid: cid,
    },
  });
}

export function getCode(mobilePhone) {
  return fetch({
    url: api.getCode,
    method: 'post',
    data: {
      mumber: mobilePhone,
    },
  });
}

export function updatePassword(mobilePhone, code, password) {
  return fetch({
    url: api.updatePassword,
    method: 'post',
    data: {
      mumber: mobilePhone,
      code: code,
      password: password,
    },
  });
}

export function updatePhone(mobilePhone, code) {
  return fetch({
    url: api.updatePhone,
    method: 'post',
    data: {
      mumber: mobilePhone,
      code: code,
    },
  });
}

export function city() {
  return fetch({
    url: api.city,
    method: 'get',
  });
}

export function searchField(name) {
  return fetch({
    url: api.searchField,
    method: 'get',
    params: {
      name: name,
    },
  });
}

export function shareLesson(fid, id) {
  return fetch({
    url: api.shareLesson,
    method: 'post',
    data: {
      fid: fid,
      id: id,
    },
  });
}

export function income() {
  return fetch({
    url: api.income,
    method: 'post',
  });
}

export function revenueList(page, flag, type) {
  return fetch({
    url: api.revenueList,
    method: 'post',
    data: {
      page: page,
      flag: flag,
      type: type,
    },
  });
}

export function agentList(page) {
  return fetch({
    url: api.agentList,
    method: 'get',
    params: {
      page: page,
    },
  });
}

// export function wxLogin(code, state) {
//   return fetch({
//     url: api.wxLogin + code,
//     method: 'get',
//     params: {
//       silent: state,
//     },
//   });
// }
//
// export function wxConfig(jsapi, url) {
//   return fetch({
//     url: api.wxConfig,
//     method: 'get',
//     params: {
//       jsapi: jsapi,
//       url: url,
//     },
//   });
// }

export function orderCash(page) {
  return fetch({
    url: api.orderCash,
    method: 'get',
    params: {
      user_type: 2,
      page: page,
    },
  });
}

export function cashMony(real_name, user_note, amount, wallet_type) {
  return fetch({
    url: api.cashMony,
    method: 'post',
    data: {
      real_name: real_name,
      user_note: user_note,
      amount: amount,
      wallet_type: wallet_type,
    },
  });
}

export function oneLesson(id, flag) {
  return fetch({
    url: api.oneLesson,
    method: 'get',
    params: {
      id: id,
      flag: flag,
    },
  });
}

export function lessonVod(id, lesson_id, trys) {
  return fetch({
    url: api.lessonVod,
    method: 'post',
    data: {
      id: id,
      lesson_id: lesson_id,
      try: trys,
    },
  });
}

export async function numberInfo() {
  return await fetch({
    url: api.numberInfo,
    method: 'get',
  });
}

export function payLesson() {
  return fetch({
    url: api.payLesson,
    method: 'get',
    params: {
      user_type: 2,
    },
  });
}

export function lessonFree(cid) {
  return fetch({
    url: api.lessonFree,
    method: 'get',
    params: {
      cid: cid || localStorage.getItem('city_id') || meStore.cityId,
    },
  });
}

export function freeApply(id) {
  return fetch({
    url: api.freeApply,
    method: 'post',
    data: {
      id: id,
    },
  });
}

export function extension(share_id, device) {
  return fetch({
    url: api.extension,
    method: 'post',
    data: {
      share_id: share_id,
      device: device,
    },
  });
}

export function BackRegister(number, code, dst_id, tag) {
  return fetch({
    url: api.BackRegister,
    method: 'post',
    data: {
      mumber: number,
      code: code,
      dst_id: dst_id,
      tag: tag,
    },
  });
}

// export function checkDst(number, code, dst_id, tag) {
//   return fetch({
//     url: api.checkDst,
//     method: 'post',
//     data: {
//       mumber: number,
//       code: code,
//       dst_id: dst_id,
//       tag: tag,
//     },
//   });
// }

// export function notify() {
//   return fetch({
//     url: api.notify,
//     method: 'post',
//   });
// }
//
// export function urlRecord(url, address, longitude, latitude) {
//   return fetch({
//     url: api.urlRecord,
//     method: 'post',
//     data: {
//       url: url,
//       address: address,
//       longitude: longitude,
//       latitude: latitude,
//     },
//   });
// }

export function myQrcode() {
  return fetch({
    url: api.myQrcode,
    method: 'post',
  });
}

export function qrcodeDetails(id) {
  return fetch({
    url: api.qrcodeDetails,
    method: 'get',
    params: {
      qr_id: id,
    },
  });
}

export function orderProd(lesson_id, url) {
  return fetch({
    url: api.orderProd,
    method: 'post',
    data: {
      lesson_id: lesson_id,
      url: url,
    },
  });
}

export function checkData() {
  return fetch({
    url: api.checkData,
    method: 'get',
    params: {},
  });
}

export function codefocus(cate, url) {
  return fetch({
    url: api.codefocus,
    method: 'post',
    data: {
      cate: cate,
      url: url,
    },
  });
}
import { currentCoach } from '@/views/circuitDiagram/LinelistSwitchCoach.vue';
export function linefigure(lessons_id, type) {
  const mid = currentCoach()?.id;
  return fetch({
    url: api.linefigure,
    method: 'get',
    params: {
      lessons_id: lessons_id,
      type: type,
      mid,
    },
  });
}

export function studentline(type) {
  return fetch({
    url: api.studentline,
    method: 'post',
    data: {
      type: type,
    },
  });
}

export function linechange(obj) {
  return fetch({
    url: api.linechange,
    method: 'post',
    data: obj,
  });
}

export function comments(tid, page, type) {
  return fetch({
    url: api.comments,
    method: 'get',
    params: {
      tid: tid,
      page: page,
      type: type,
    },
  });
}

export function commentsp(tid, score, content, topic_type) {
  return fetch({
    url: api.commentsp,
    method: 'post',
    data: {
      tid: tid,
      score: score,
      content: content,
      topic_type: topic_type,
    },
  });
}

export function focuspush(push, type) {
  return fetch({
    url: api.focuspush,
    method: 'post',
    data: {
      push: push,
      type: type,
    },
  });
}

export function openpush() {
  return fetch({
    url: api.openpush,
    method: 'post',
    data: {},
  });
}

export function getGuest() {
  return fetch({
    url: api.getGuest,
    method: 'post',
    data: {},
  });
}

export function carpublish(giftobj) {
  return fetch({
    url: api.carpublish,
    method: 'post',
    data: giftobj,
  });
}

export function carindex(city_id, keyword, page, is_self) {
  return fetch({
    url: api.carindex,
    method: 'get',
    params: {
      city_id: city_id,
      keyword: keyword,
      p: page,
      is_self: is_self,
    },
  });
}

// export function fileSignature() {
//   return fetch({
//     url: api.fileSignature,
//     method: 'get',
//     params: {},
//   });
// }

export function carinfo(id) {
  return fetch({
    url: api.carinfo,
    method: 'get',
    params: {
      id: id,
    },
  });
}

export function cardel(id, status) {
  return fetch({
    url: api.cardel,
    method: 'post',
    data: {
      id: id,
      status: status,
    },
  });
}

// export function provinces() {
//   return fetch({
//     url: api.provinces,
//     method: 'get',
//     params: {},
//   });
// }
//
// export function citys(pid) {
//   return fetch({
//     url: api.citys,
//     method: 'get',
//     params: {
//       pid: pid,
//     },
//   });
// }

export function joblist(page, type, title, city_id) {
  return fetch({
    url: api.joblist,
    method: 'get',
    params: {
      page: page,
      type: type,
      title: title,
      city_id: city_id,
    },
  });
}

export function jobpublish(giftobj) {
  return fetch({
    url: api.jobpublish,
    method: 'post',
    data: giftobj,
  });
}

export function jobinfo(id) {
  return fetch({
    url: api.jobinfo,
    method: 'get',
    params: {
      id: id,
    },
  });
}

export function jobstatus(id, status) {
  return fetch({
    url: api.jobstatus,
    method: 'post',
    data: {
      id: id,
      status: status,
    },
  });
}

export function jobcity(type) {
  return fetch({
    url: api.jobcity,
    method: 'get',
    params: {
      type: type,
    },
  });
}

// export function fileUpload(file) {
//   return fetch({
//     url: api.fileUpload,
//     method: 'post',
//     data: {
//       file: file,
//     },
//   });
// }

export function tranpublist(obj) {
  return fetch({
    url: api.tranpublist,
    method: 'post',
    data: obj,
  });
}

export function tranindex(city_id, p, is_self, accept_member) {
  return fetch({
    url: api.tranindex,
    method: 'get',
    params: {
      city_id: city_id,
      p: p,
      is_self: is_self,
      accept_member: accept_member,
    },
  });
}

export function traninfo(id) {
  return fetch({
    url: api.traninfo,
    method: 'get',
    params: {
      id: id,
    },
  });
}

export function transtatus(id, status) {
  return fetch({
    url: api.transtatus,
    method: 'post',
    data: {
      id: id,
      status: status,
    },
  });
}

export function commentReply(comment_id, reply_id, comment) {
  return fetch({
    url: api.commentReply,
    method: 'post',
    data: {
      comment_id: comment_id,
      reply_id: reply_id,
      comment: comment,
    },
  });
}
// export function liveness() {
//   return fetch({
//     url: api.liveness + window.localStorage.getItem('token'),
//     method: 'post'
//   })
// }

export function caradslist() {
  return fetch({
    url: api.caradslist,
    method: 'post',
    data: {},
  });
}

export function carads() {
  return fetch({
    url: api.carads,
    method: 'post',
    data: {},
  });
}

export function addcarads(car_id) {
  return fetch({
    url: api.addcarads,
    method: 'post',
    data: {
      car_id: car_id,
    },
  });
}

export function activity() {
  return fetch({
    url: api.activity,
    method: 'get',
    params: {},
  });
}

export function actinfo(id) {
  return fetch({
    url: api.actinfo,
    method: 'get',
    params: {
      id: id,
    },
  });
}

export function inside(id) {
  return fetch({
    url: api.inside,
    method: 'post',
    data: {
      id: id,
    },
  });
}

export function entWx(ent_wx) {
  return fetch({
    url: api.entWx,
    method: 'get',
    params: {
      ent_wx: ent_wx,
    },
  });
}

// export function intActivity(address) {
//   return fetch({
//     url: api.intActivity,
//     method: 'post',
//     data: {
//       address: address,
//     },
//   });
// }
//
// export function actDetails(acti_id) {
//   return fetch({
//     url: api.actDetails,
//     method: 'post',
//     data: {
//       acti_id: acti_id,
//     },
//   });
// }
//
// export function exchange(acti_id) {
//   return fetch({
//     url: api.exchange,
//     method: 'post',
//     data: {
//       acti_id: acti_id,
//     },
//   });
// }

export function goods() {
  return fetch({
    url: api.goods,
    method: 'get',
    params: {},
  });
}

export function goodsDetails(goods_id) {
  return fetch({
    url: api.goodsDetails,
    method: 'post',
    data: {
      goods_id: goods_id,
    },
  });
}

export function integralRecord(page, y) {
  return fetch({
    url: api.integralRecord,
    method: 'post',
    data: {
      page: page,
      y: y,
    },
  });
}

export function addressList() {
  return fetch({
    url: api.addressList,
    method: 'get',
    params: {},
  });
}

export function addressFind(obj) {
  return fetch({
    url: api.addressFind,
    method: 'post',
    data: obj,
  });
}

export function changeProduct(
  id,
  address_id,
  goods_num,
  device_id,
  field_id,
  field_name
) {
  return fetch({
    url: api.changeProduct,
    method: 'post',
    data: {
      id: id,
      address_id: address_id,
      goods_num: goods_num,
      device_id: device_id,
      field_id: field_id,
      field_name: field_name,
    },
  });
}

export function orderlist(page, type, y) {
  return fetch({
    url: api.orderlist,
    method: 'post',
    data: {
      page: page,
      type: type,
      y: y,
    },
  });
}

export function integDetails(order_id, code) {
  return fetch({
    url: api.integDetails,
    method: 'post',
    data: {
      order_id: order_id,
      code: code,
    },
  });
}

// export function addActivity(acti_id) {
//   return fetch({
//     url: api.addActivity,
//     method: 'post',
//     data: {
//       acti_id: acti_id,
//     },
//   });
// }

export function description() {
  return fetch({
    url: api.description,
    method: 'post',
    data: {},
  });
}

// export function integTions() {
//   return fetch({
//     url: api.integTions,
//     method: 'post',
//     data: {},
//   });
// }

export function showTab(type) {
  return fetch({
    url: api.showTab,
    method: 'post',
    data: {
      type: type,
    },
  });
}

export function marki(obj) {
  return fetch({
    url: api.marki,
    method: 'post',
    data: obj,
  });
}

export function etcmarki(order_id, type, code) {
  return fetch({
    url: api.etcmarki,
    method: 'post',
    data: {
      order_id: order_id,
      type: type,
      code: code,
    },
  });
}

// export function orderDetails(code) {
//   return fetch({
//     url: api.orderDetails,
//     method: 'post',
//     data: {
//       code: code,
//     },
//   });
// }

export function compList(state) {
  return fetch({
    url: api.compList,
    method: 'post',
    data: {
      complaint_state: state,
    },
  });
}

export function compDetails(complaint_id) {
  return fetch({
    url: api.compDetails,
    method: 'post',
    data: {
      complaint_id: complaint_id,
    },
  });
}

export function intRefund(order_id, note, checked) {
  return fetch({
    url: api.intRefund,
    method: 'post',
    data: {
      order_id: order_id,
      note: note,
      checked: checked,
    },
  });
}

export function replacePlay(obj) {
  return fetch({
    url: api.replacePlay,
    method: 'post',
    data: obj,
  });
}

export function installTime(obj) {
  return fetch({
    url: api.installTime,
    method: 'post',
    data: obj,
  });
}

export function updateLicense(obj) {
  return fetch({
    url: api.updateLicense,
    method: 'post',
    data: obj,
  });
}

export function licenseInfo(obj) {
  return fetch({
    url: api.licenseInfo,
    method: 'get',
    params: obj,
  });
}

export function punchInfo(obj) {
  return fetch({
    url: api.punchInfo,
    method: 'get',
    params: obj,
  });
}

export function orderDstExt(obj) {
  return fetch({
    url: api.orderDstExt,
    method: 'get',
    params: obj,
  });
}

export function spreadSetting(obj) {
  return fetch({
    url: api.spreadSetting,
    method: 'post',
    data: obj,
  });
}

export function spreadLst(obj) {
  return fetch({
    url: api.spreadLst,
    method: 'get',
    params: obj,
  });
}

export function bindingSpread(obj) {
  return fetch({
    url: api.bindingSpread,
    method: 'post',
    data: obj,
  });
}

export function loginIntegral(obj) {
  return fetch({
    url: api.loginIntegral,
    method: 'get',
    params: obj,
  });
}

export function lineSyncName(obj) {
  return fetch({
    url: api.lineSyncName,
    method: 'get',
    params: obj,
  });
}

export function integralCode(obj) {
  return fetch({
    url: api.integralCode,
    method: 'post',
    data: obj,
  });
}
