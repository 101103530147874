import useMeStore from '@/features/auth/useMeStore';
import { loginIntegral } from '@/utils/api';
export default {
  components: {},
  data() {
    return {
      show: false,
      popup: {
        show: true
      },
      me: useMeStore().me
    };
  },
  mounted() {
    if (this.me.is_regional == 1) return;
    let is_sign = localStorage.getItem('is_sign') || false,
      day_end = localStorage.getItem('day_end') || 0,
      day_sart = new Date().getTime();
    if (!is_sign || day_end - day_sart < 0) {
      loginIntegral().then(res => {
        if (res.code == 1) {
          this.show = true;
          localStorage.setItem('is_sign', true);
          day_end = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1).getTime();
          localStorage.setItem('day_end', day_end);
        }
      });
      setTimeout(() => {
        this.show = false;
      }, 2000);
    }
  },
  methods: {
    maskClick() {
      setTimeout(() => {
        this.show = false;
      }, 100);
    }
  }
};