export const addLesson = '/v2/distributor/manage-lessons'; //添加课程界面数据接口
export const batchSelect = '/v2/distributor/add-lesson'; //分销员 课程或合集添加
export const remove = '/v2/distributor/del-lesson'; //分销员 课程或合集 移除
export const myLesson = '/v2/distributor/dst-index'; //分销员 分销列表
export const city = '/v2/city'; //省份城市
export const searchField = '/v2/city/search-field'; //城市、考场搜索
export const shareLesson = '/v2/lesson/share'; //分享课程或考场

export const income = '/v2/distributor/income'; //分销员，我的收益页面 头部数据
export const revenueList = '/v2/distributor/revenue-list'; //分销员收益列表

export const agentList = '/v2/distributor/agent-list'; //下级分销员 数据列表

export const wxLogin = '/wx?code='; //微信登录
export const wxConfig = '/wx/sdk'; //微信授权

export const orderCash = '/v2/cash'; //提现列表
export const cashMony = '/v2/cash/cash'; //提现
export const oneLesson = '/v2/lesson/info'; //详情页单个课程
export const lessonVod = '/v2/lesson/vod'; //获取视频播放凭证

export const numberInfo = '/v2/student/member-info'; //用户基本信息

export const payLesson = '/v2/cash/money'; //提现页面信息
export const lessonFree = '/v2/distributor/lessons'; //我的课程列表页面
export const freeApply = '/v2/distributor/apply'; //申请免费观看

export const extension = '/v2/student/extension'; //分销推广关系绑定
export const BackRegister = '/v2/student/reg-dst'; //注册一二级分销员
export const checkDst = '/v2/student/check-dst'; //绑定
export const getCode = 'v2/student/sms'; //手机获取验证码
export const updatePassword = '/v2/student/update-pwd'; //更改密码
export const updatePhone = '/v2/student/binding-mobile'; //更改手机号
export const notify = '/v1/student/notify'; //未读消息提醒

export const urlRecord = '/v2/student/login-log'; //url记录
export const myQrcode = '/v2/coach/qr-list'; //我的二维码
export const qrcodeDetails = '/v2/coach/qr-lessons'; //二维码详情
export const orderProd = '/v2/order/prod'; //购买
export const checkData = '/v2/distributor/check-data'; //总计金额，累计笔数

export const codefocus = '/v2/wx/focus'; //获取二维码
export const linefigure = '/v2/lesson/line-figure'; //线路图下载接口
export const studentline = '/v2/student/line'; //分销员线路图分享信息
export const linechange = '/v2/student/line-change'; //修改分销员线路图分享信息

export const comments = '/v2/comment'; //评论数据
export const commentsp = '/v2/comment/publish'; //用户发布课程评论
export const focuspush = '/v2/distributor/focus-push'; //开启关闭自动推送
export const openpush = '/v2/distributor/open-push'; //是否显示开启关闭按钮
export const getGuest = '/v2/comment/the-guest'; //获取客服二维码

export const carpublish = '/v2/car/publish'; //发布车辆
export const carindex = '/v2/car/index'; //车辆转让列表
export const fileSignature = '/v2/file/signature'; //阿里云文件上传
export const carinfo = '/v2/car/info'; //车辆详情
export const cardel = '/v2/car/status'; //删除车辆

export const provinces = '/v2/city/provinces'; //获取已有招聘省市信息
export const citys = '/v2/city/citys'; //获取省份下面的城市
export const joblist = '/v2/job/list'; //招聘信息编辑列表
export const jobpublish = '/v2/job/publish'; //发布招聘信息
export const jobinfo = '/v2/job/info'; //职位详情
export const jobstatus = '/v2/job/status'; //职位上下架
export const jobcity = '/v2/city/city'; //获取已有招聘省市信息
export const fileUpload = '/v2/file/upload'; //图片上传

export const tranpublist = '/v2/training/publish'; //委培发布
export const tranindex = '/v2/training/index'; //委培列表
export const traninfo = '/v2/training/info'; //委培详情
export const transtatus = '/v2/training/status'; //修改状态

export const commentReply = '/v2/comment/reply'; //回复评论
// export const liveness = '/v2/student/liveness' //日活跃数
export const caradslist = '/v2/student/car-ads-list'; //广告列表
export const carads = '/v2/student/car-ads'; //是否显示汽车广告
export const addcarads = '/v2/student/add-car-ads'; //登记汽车用户
export const activity = '/v2/activity/index'; //活动列表
export const actinfo = '/v2/activity/info'; //活动详情
export const inside = '/v2/activity/inside'; //参加活动

export const entWx = '/v2/distributor/ent-wx'; //申请观看弹框

export const intActivity = '/v2/integral/activity'; /*废弃*/
export const actDetails = '/v2/integral/act-details'; /*废弃*/
export const exchange = '/v2/integral/exchange'; /*废弃*/
export const goods = '/v2/integral/goods'; //积分商城商品列表
export const goodsDetails = '/v2/integral/goods-details'; //积分商城商品详情
export const integralRecord = '/v2/integral/integral-record'; //积分商城商品兑换记录
export const addressList = '/v2/integral/address-list'; //积分商城收货地址列表
export const addressFind = '/v2/integral/address-find'; //积分商城默认收货地址
export const changeProduct = '/v2/integral/ex-change-product'; //积分兑换商品
export const orderlist = '/v2/integral/orderlist'; //积分商城订单列表
export const integDetails = '/v2/integral/order-details'; //积分商城订单详情
export const addActivity = '/v2/integral/add-activity'; /*废弃*/
export const description = '/v2/integral/description'; //积分商城活动说明
export const integTions = '/v2/integral/tions'; /* 废弃 */

export const showTab = '/v2/integral/show-tab'; //积分商城活动入口权限
export const marki = '/v2/integral/marki'; //积分订单数量
export const etcmarki = '/v2/integral/etc-marki'; //积分订单配送状态修改
export const orderDetails = '/v2/integral/order-details'; //积分订单详情
export const compList = '/v2/comment/comp-list'; //投诉列表
export const compDetails = '/v2/comment/comp-details'; //投诉详情
export const intRefund = '/v2/integral/refund'; //积分商品退款
export const installTime = '/v2/integral/install-time'; //预约安装时间
// export const marki_num = '/v2/integral/marki_num'

export const replacePlay = '/v2/lesson/replace-play'; //更换用户课程播放源

export const updateLicense = '/v2/member-license/update-license'; //编辑营业执照
export const licenseInfo = '/v2/member-license/info'; //营业执照详情

export const punchInfo = '/v2/coach/punch'; // 打卡
export const orderDstExt = '/v2/order/dst-ext'; // 账单推送

export const loginIntegral = '/v2/integral/login-integral'; //  每天登录领积分
export const spreadSetting = '/v2/coach/spread-setting'; //  地推绑定设置
export const spreadLst = '/v2/coach/spread-list'; //  地推码列表
export const bindingSpread = '/v2/coach/binding-spread'; //  绑定地推人员
export const lineSyncName = '/v2/student/line-sync-name'; //  同步线路图信息

export const integralCode = '/v2/integral/order-code'; //  积分 code 解锁
