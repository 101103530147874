var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_vm.show ? _c('div', {
    staticClass: "fixed inset-0 translate-z-0 bg-white/10 z-50"
  }) : _vm._e(), _vm.show ? _c('van-popup', {
    on: {
      "close": _vm.maskClick
    },
    model: {
      value: _vm.popup.show,
      callback: function ($$v) {
        _vm.$set(_vm.popup, "show", $$v);
      },
      expression: "popup.show"
    }
  }, [_c('div', {
    staticClass: "fixed-box",
    on: {
      "click": _vm.maskClick
    }
  }, [_c('img', {
    staticClass: "img-box",
    attrs: {
      "src": require("../assets/img/sign_img.png")
    }
  }), _c('div', {
    staticClass: "abso-box"
  }, [_c('div', [_vm._v("恭喜您")]), _c('div', [_vm._v("成功领取1积分")])])])]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };