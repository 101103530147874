import '@/config/bootstrap';
import Vue from 'vue';
import App from './App';
import router from './router';
import axios from '@/features/api/axios';
import pinia from './pinia';
// import './assets/css/main.css';

import stopChangeFontSize from '@/features/weixin/utils/stopChangeFontSize';
stopChangeFontSize();

import unVant from '@/features/common/libs/unvant';
Vue.use(unVant);

Vue.prototype.$axios = axios;

Vue.config.productionTip = false;
Vue.config.ignoredElements = [/^wx-/];

import authCheck from '@/features/auth/check';
import { Dialog } from 'vant';

authCheck()
  .then(() => {
    const app = new Vue({
      router,
      pinia,
      render: (h) => h(App),
    });
    router.onReady(() => app.$mount('#app'));
  })
  .catch((e) => {
    if (+e?.status === 402 && e.message) {
      location.replace('/?#/update?t=' + e.message);
      return;
    }
    if (!e) return;
    Dialog.alert({ message: e?.message || e }).then(() => {
      location.reload();
    });
    throw e;
  });
