import "core-js/modules/es.regexp.exec.js";
import { weixinAuthorizeUserInfo } from '@/features/auth/check';
import useMeStore from '@/features/auth/useMeStore';
import { tokenStorage } from '@/features/auth/token';
export default {
  components: {},
  data() {
    return {
      popup: {
        show: false
      },
      me: useMeStore().me
    };
  },
  mounted() {},
  computed: {
    show() {
      var _this$me;
      if (this.$route.path.match(/\/map/i)) {
        return 0;
      }
      return !!+((_this$me = this.me) === null || _this$me === void 0 ? void 0 : _this$me.is_new);
    }
  },
  methods: {
    maskClick() {
      this.popup.show = true;
      tokenStorage('');
      weixinAuthorizeUserInfo();
    }
  }
};